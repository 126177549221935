import React from 'react'
import { PageTemplate } from '../templates/page'
import Button from '../components/shortcodes/Button'

const ThanksPage = ( {location} ) => {
  return (
    <PageTemplate 
      title="Thank You"
      pageIdentifier="thank-you"
      pageClass="hide-cart has-text-centered"
      showCta={false}
      showContactForm={false}
    >
      <p><strong>We received your enquiry and will get back to you as soon as possible — usually within 24h.</strong></p>

      <Button url="/" text="Go Back" />
    </PageTemplate>
  )
}

export default ThanksPage